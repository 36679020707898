import { action, autorun, computed, observable, makeObservable } from 'mobx';
import { toast } from 'react-hot-toast';
import numeral from 'numeral';
import { enableStaticRendering } from 'mobx-react-lite';

import { formatForCurrency, sanitizeSourceMetadata } from '@/utils';
import statuses from '@/statuses';
import { trackEvent, trackAction } from '@/tracker';
import config from '@/config';
import { createClient } from '@/feathers-client';
import currencies from '@data/currencies.json';
import Bugsnag from '@/bugsnag';

// eslint-disable-next-line react-hooks/rules-of-hooks
enableStaticRendering(typeof window === 'undefined');
const cartStorageKey = 'cartv3';
const favoritesStorageKey = 'favoritesv1';

const currenciesToLimit = [
  'USD',
  'CAD',
  'AUD',
  'GBP',
  'EUR'
];

export default class Cart {
  //TODO: Persist these 2 to localStorage
  items = [];
  favorites = [];
  referralCode = null;
  // end of persisted

  visible = false;
  creatingCharge = false;
  trackedPayment = false;
  lastOrder = null;
  loadingScript = false;
  supportBeaconLoaded = false;

  gclid = null;
  fbclid = null;
  rdt_cid = null;
  utmTags = {};

  dailyLimit = 9500;
  charge = null;
  order = null;
  quote = null;
  paymentSubmitted = false;
  showDappCheckout = false;
  isHydrated = false;

  constructor () {
    makeObservable(this, {
      loadingScript: observable,
      supportBeaconLoaded: observable,
      items: observable,
      favorites: observable,
      referralCode: observable,
      utmTags: observable,
      creatingCharge: observable,
      lastOrder: observable,
      visible: observable,
      add: action,
      remove: action,
      incrementQuantity: action,
      decrementQuantity: action,
      clearCart: action,
      toggleCart: action,
      trackedPayment: observable,
      cartCurrency: computed,
      cartCountry: computed,
      total: computed,
      totalItems: computed,
      totalInCents: computed,
      checkoutWithSingle: action,
      setReferralCode: action,
      setUTMTags: action,
      isHydrated: observable,
      hydrate: action,
      charge: observable,
      order: observable,
      quote: observable,
      qrCodeValue: computed,
      selectedCurrency: computed,
      unpaidAmount: computed,
      chainId: computed,
      paymentSubmitted: observable,
      getQuote: action,
      createOrder: action,
      setPaymentSubmitted: action,
      setCharge: action,
      setQuote: action,
      setOrder: action,
      accountNumber: computed,
      showDappCheckout: observable,
      setShowDappCheckout: action,
      addToFavorites: action,
      removeFromFavorites: action,
      encodedItems: computed,
      populateCartFromEncodedItems: action,
      phantomUrl: computed
    });
  }

  initFeathersClients = () => {
    if (this.giftcardsAPIClient || this.paymentsAPIClient || this.blockchainAPIClient) {
      return;
    }

    if (typeof window !== 'undefined') {
      this.giftcardsAPIClient = createClient(config.commerceApiUrl);
      this.paymentsAPIClient = createClient(config.paymentsApiUrl);
      this.blockchainAPIClient = createClient(config.blockchainApiUrl);

      this.giftcardsAPIClient.service('widget-orders').on('updated', ({ id, charge, order }) => {
        if (this.order && this.order.id === id) {
          if (typeof Bugsnag.leaveBreadcrumb === 'function') Bugsnag.leaveBreadcrumb('Order updated', { id, charge, order });
          this.setOrder(order);
          this.setCharge(charge);

          if (charge.statusCode >= statuses.processing) {
            const recentCurrencies = JSON.parse(localStorage.getItem('recentCurrencies')) || [];
            const [paymentProtocol] = Object.keys(charge.payments);
            if (typeof Bugsnag.leaveBreadcrumb === 'function') Bugsnag.leaveBreadcrumb('Payment detected', { paymentProtocol });
            this.setPaymentSubmitted(false);

            if (!recentCurrencies.includes(paymentProtocol)) {
              recentCurrencies.unshift(paymentProtocol);
              localStorage.setItem('recentCurrencies', JSON.stringify(recentCurrencies));
            }
          }
        }
      });
    }
  };

  async loadScriptAsync (uri) {
    return new Promise((resolve, reject) => {
      const tag = document.createElement('script');
      tag.src = uri;
      tag.id = 'commerce-sdk-script-tag';
      tag.async = true;
      tag.onload = () => {
        resolve();
      };
      tag.onerror = (e) => {
        reject(new Error('Error loading Commerce SDK'));
      }
      const currentTag = document.getElementById(tag.id);
      if (currentTag) {
        currentTag.parentNode.removeChild(currentTag);
      }
      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    });
  };

  async storeQueryParams () {
    const queryParams = new URLSearchParams(window.location.search);
    const r = queryParams.get('r');
    const fbclid = queryParams.get('fbclid');
    const gclid = queryParams.get('gclid');
    const gclsrc = queryParams.get('gclsrc');
    const rdt_cid = queryParams.get('rdt_cid');

    r && this.setReferralCode(r);
    this.setGoogleClickId(gclid, gclsrc);
    this.setFacebookClickId(fbclid);
    this.setRedditClickId(rdt_cid);
    this.setUTMTags(queryParams);
  }

  async hydrate () {
    console.log('store hydrated');
    this.storeQueryParams();
    const { storage } = require('local-storage-fallback');
    this.storage = storage;

    if (this.storage.getItem(cartStorageKey)) {
      const obj = JSON.parse(this.storage.getItem(cartStorageKey));
      this.items = obj.items;

      if (obj.referralCode) {
        this.referralCode = obj.referralCode;
      }

      if (obj.utmTags) {
        this.utmTags = obj.utmTags;
      }
    }

    if (this.storage.getItem(favoritesStorageKey)) {
      this.favorites = JSON.parse(this.storage.getItem(favoritesStorageKey));
    }

    autorun(() => {
      const obj = {
        items: this.items,
        referralCode: this.referralCode,
        utmTags: this.utmTags
      };
      const json = JSON.stringify(obj);
      this.storage.setItem(cartStorageKey, json);
    });

    autorun(() => {
      const json = JSON.stringify(this.favorites);
      this.storage.setItem(favoritesStorageKey, json);
    });

    this.isHydrated = true;
  }

  async setReferralCode (referralCode) {
    this.referralCode = referralCode;
  }

  getExpiryRecord (value) {
    var expiryPeriod = 90 * 24 * 60 * 60 * 1000; // 90 day expiry in milliseconds

    var expiryDate = new Date().getTime() + expiryPeriod;
    return {
      value: value,
      expiryDate: expiryDate
    };
  }

  async setGoogleClickId (gclid, gclsrc) {
    await this.hydration;
    let gclidRecord = null;
    const isGclsrcValid = !gclsrc || gclsrc.indexOf('aw') !== -1;

    if (gclid && isGclsrcValid) {
      gclidRecord = this.getExpiryRecord(gclid);
      this.storage.setItem('gclid', JSON.stringify(gclidRecord));
    }

    var gclidObj = gclidRecord || JSON.parse(this.storage.getItem('gclid'));
    var isGclidValid = gclidObj && new Date().getTime() < gclidObj.expiryDate;

    if (isGclidValid) {
      this.gclid = gclidObj.value;
    }
  }

  async setFacebookClickId (fbclid) {
    await this.hydration;
    let fbclidRecord = null;
    if (fbclid) {
      fbclidRecord = this.getExpiryRecord(fbclid);
      this.storage.setItem('fbclid', JSON.stringify(fbclidRecord));
    }

    var fbclidObj = fbclidRecord || JSON.parse(this.storage.getItem('fbclid'));
    var isFbclidValid = fbclidObj && new Date().getTime() < fbclidObj.expiryDate;

    if (isFbclidValid) {
      this.fbclid = fbclidObj.value;
    }
  }

  async setRedditClickId (rdt_cid) {
    await this.hydration;
    let rdt_cidRecord = null;
    if (rdt_cid) {
      rdt_cidRecord = this.getExpiryRecord(rdt_cid);
      this.storage.setItem('rdt_cid', JSON.stringify(rdt_cidRecord));
    }

    var rdt_cidObj = rdt_cidRecord || JSON.parse(this.storage.getItem('rdt_cid'));
    var isRdt_cidValid = rdt_cidObj && new Date().getTime() < rdt_cidObj.expiryDate;

    if (isRdt_cidValid) {
      this.rdt_cid = rdt_cidObj.value;
    }
  }

  async setUTMTags (params) {
    params.forEach((value, key) => {
      if (key.startsWith('utm_')) {
        this.utmTags[key] = value;
      }
    });
  }

  get totalItems () {
    return this.items.map(item => item.quantity).reduce((a, b) => a + b, 0);
  }

  //TODO: Handle strings in here
  get total () {
    if (!this.totalItems) {
      return 0;
    }
    return this.items.map(item => item.quantity * item.amount).reduce((a, b) => a + b, 0) / 100;
  }

  //TODO: Handle strings in here
  get totalInCents () {
    return this.total * 100;
  }

  get cartCurrency () {
    return !!this.items.length ? this.items[0].product.currency : null;
  }

  get cartCountry () {
    return !!this.items.length ? this.items[0].brand.country : null;
  }

  get encodedItems () {
    const cleanedUpItems = this.items.map(item => ({
      brand: item.brand.id,
      product: item.product.id,
      quantity: item.quantity,
      amount: item.amount,
      isTopUp: item.isTopUp,
      isESim: item.isESim,
      ...(item.accountNumber ? { accountNumber: item.accountNumber } : {})
    }));

    return btoa(JSON.stringify(cleanedUpItems));
  }

  get phantomUrl () {
    if (typeof window === 'undefined') return null;
    return `${window.location.origin}/checkout?items=${this.encodedItems}&chargeId=${this.charge?.id}&quoteId=${this.quote?.id}&utm_source=checkout_phantom`;
  }

  populateCartFromEncodedItems = async (encodedItems) => {
    const items = JSON.parse(atob(encodedItems));
    const populatedItems = await Promise.all(items.map(async item => {
      const brand = await this.giftcardsAPIClient.service('brands').get(item.brand);
      const product = await this.giftcardsAPIClient.service('products').get(item.product);

      return {
        ...item,
        brand,
        product,
      };
    }));

    this.items = populatedItems;
  }

  toggleCart = () => {
    this.visible = !this.visible;
  };

  initDappCheckout = () => {
    console.log('initDappCheckout');
    this.setShowDappCheckout(true);
  };

  add = (quantity, amount, brand, product, isTopUp = false, accountNumber) => {
    const isESim = brand.type === 'esim';
    if (this.items.some(item => item.isTopUp || item.isESim) || ((isTopUp || isESim) && this.items.length)) {
      let message = 'You must purchase this card separately from the items already in your cart.';
      if (this.items.some(item => item.brand.id === brand.id)) {
        message = 'You can only purchase one top up or eSim at a time.';
      }

      toast.error(message);
      return false;
    }

    // TODO: How does this handle quoteCurrency being different then the currency
    if (this.cartCurrency && this.cartCurrency !== product.currency) {
      let message = 'Items in your cart must be the same currency.';
      toast.error(message);
      return false;
    }

    if (this.items.length && this.items[0].product.quoteCurrency !== product.quoteCurrency) {
      let message = 'You must purchase this card separately from the items already in your cart.';
      toast.error(message);
      return false;
    }

    //TODO: Handle strings in here
    if ((quantity * amount) / 100 + this.total >= this.dailyLimit && currenciesToLimit.includes(product.cartCurrency)) {
      let message = `To comply with AML policies we only allow $${this.dailyLimit} in products to be purchased by each customer every 24 hours`;
      toast.error(message);
      return false;
    }
    let itemExisted = false;
    this.items.map(item => {
      //TODO: Handle strings in here for amount
      // Update the quantity if the item exists
      if (item.brand.id === brand.id && item.amount === amount) {
        itemExisted = true;
        item.quantity = item.quantity + quantity;
        return;
      }
    });
    if (!itemExisted) {
      this.items.push({ quantity, amount, brand, product, isTopUp, isESim, accountNumber });
    }
    let message = `Added a ${formatForCurrency({ amount, currency: product.currency })} '${brand.name}' gift card to your cart.`;
    if (quantity > 1) {
      message = `Added ${quantity} x ${formatForCurrency({ amount, currency: product.currency })} '${brand.name}' gift cards to your cart.`;
    }

    this.setCharge(null);
    this.setQuote(null);
    this.setOrder(null);
    this.setPaymentSubmitted(false);

    toast.success(message);
    return true;
  };

  //TODO: Handle strings in here
  remove = (itemToRemove) => {
    const isItem = i => i.brand.id === itemToRemove.brand.id && i.amount === itemToRemove.amount;
    const filtered = this.items.filter(item => !isItem(item));

    this.items = filtered;
    this.setCharge(null);
    this.setQuote(null);
    this.setOrder(null);
    this.setPaymentSubmitted(false);

    trackAction('remove', {
      id: itemToRemove.brand.id,
      name: `${itemToRemove.brand.name} gift card`,
      category: itemToRemove.brand.country.name,
      brand: itemToRemove.brand.name,
      price: numeral(itemToRemove.amount).divide(100).format('0.00'),
      quantity: itemToRemove.quantity
    });
    trackEvent({
      category: 'User',
      action: 'Removed from cart',
      label: itemToRemove.brand.name,
      value: itemToRemove.quantity
    });
  };

  addToFavorites = (brand) => {
    if (!this.favorites.some(favorite => favorite.id === brand.id)) {
      this.favorites.push(brand);
      toast.success(`Added ${brand.name} to your favorites.`);
    }
  }

  removeFromFavorites = (brand) => {
    const wasFavorite = this.favorites.some(favorite => favorite.id === brand.id);
    this.favorites = this.favorites.filter(favorite => favorite.id !== brand.id);
    if (wasFavorite) {
      toast.success(`Removed ${brand.name} from your favorites.`);
    }
  }

  //TODO: Handle strings in here for amount
  decrementQuantity = (itemToDecrement) => {
    if (itemToDecrement.quantity === 1) {
      this.remove(itemToDecrement);
    } else {
      this.items.map(item => {
        // Update the quantity if the item exists
        if (item.brand.id === itemToDecrement.brand.id && item.amount === itemToDecrement.amount) {
          item.quantity = item.quantity - 1;
        }
      });

      this.setCharge(null);
      this.setQuote(null);
      this.setOrder(null);
      this.setPaymentSubmitted(false);
    }

    trackAction('remove', {
      id: itemToDecrement.brand.id,
      name: `${itemToDecrement.brand.name} gift card`,
      category: itemToDecrement.brand.country.name,
      brand: itemToDecrement.brand.name,
      price: numeral(itemToDecrement.amount).divide(100).format('0.00'),
      quantity: 1
    });

    trackEvent({
      category: 'User',
      action: 'Decremented Quantity in Cart',
      label: itemToDecrement.brand.name,
      value: 1
    });
  };

  incrementQuantity = (item) => {
    this.add(1, item.amount, item.brand, item.product);

    trackAction('add', {
      id: item.brand.id,
      name: `${item.brand.name} gift card`,
      category: item.brand.country.name, // Using region for a category
      brand: item.brand.name,
      price: numeral(item.amount).divide(100).format('0.00'),
      quantity: 1
    });

    trackEvent({
      category: 'User',
      action: 'Incremented Quantity in Cart',
      label: item.brand.name,
      value: 1
    });
  };

  clearCart = () => {
    this.items = [];
    this.setCharge(null);
    this.setQuote(null);
    this.setOrder(null);
    this.setPaymentSubmitted(false);
  };

  checkoutWithSingle = async (brand, product, amount, accountNumber) => {
    return this.add(1, amount, brand, product, true, accountNumber);
  };

  createOrderPayload = (customer, platform) => {
    let apiKey;
    const { pathname, search } = window.location;
    const urlParams = new URLSearchParams(search);
    const queryStringKey = (urlParams.get('key') || '').trim();

    if (queryStringKey.length > 0) {
      apiKey = queryStringKey;
    } else if (window.bidaliProvider) {
      apiKey = window.bidaliProvider.key;
    } else if (window.valora) {
      apiKey = window.valora.key;
    } else if ((!window.bidaliProvider && !window.valora) || pathname === '/checkout') {
      apiKey = config.commerceReferrerApiKey;
    }

    const payload = {
      referralCode: this.referralCode || undefined,
      apiKey,
      customer,
      items: this.items.map(item => ({
        amount: item.amount,
        quantity: item.quantity,
        productId: item.product.id
      })),
      source: this.utmTags
    };

    if (platform) {
      payload.source = {
        ...this.utmTags,
        ...platform
      };
    }

    if (this.gclid) {
      payload.source.gclid = this.gclid;
    }

    if (this.fbclid) {
      payload.source.fbclid = this.fbclid;
    }

    if (this.rdt_cid) {
      payload.source.rdt_cid = this.rdt_cid;
    }

    const isEsimOrder = this.items.some(item => item.brand.type === 'esim');
    //TODO: Remove this once we have a way to handle esim orders with IMEI being passed
    // as accountNumber
    if (this.accountNumber && !isEsimOrder) {
      payload.accountNumber = this.accountNumber;
    }

    const sanitizedSource = sanitizeSourceMetadata(payload.source);

    payload.source = sanitizedSource;

    return payload;
  };

  createOrder = async (customer, platform = null) => {
    if (!this.paymentsAPIClient || !this.giftcardsAPIClient) {
      const error = new Error('Store not properly initialized');
      Bugsnag.notify(error);
      throw error;
    }

    try {
      const payload = this.createOrderPayload(customer, platform);
      const query = { organization: config.paymentsApiPublishableKey };
      if (typeof Bugsnag.leaveBreadcrumb === 'function') Bugsnag.leaveBreadcrumb('Creating Order', { payload });
      const { charge, order } = await this.giftcardsAPIClient.service('widget-orders').create(payload);
      this.setCharge(await this.paymentsAPIClient.service('charges').get(charge.id, { query }));
      this.setOrder(order);
    } catch (e) {
      Bugsnag.notify(e);
      throw e;
    }
  };

  getQuote = async id => {
    try {
      const query = { organization: config.paymentsApiPublishableKey };
      const quote = await this.paymentsAPIClient.service('quotes').get(id, { query });
      this.setQuote(quote);
      return quote;
    } catch (e) {
      Bugsnag.notify(e);
      throw e;
    }
  };

  getCharge = async id => {
    const query = { organization: config.paymentsApiPublishableKey };
    const charge = await this.paymentsAPIClient.service('charges').get(id, { query });
    this.setCharge(charge);
    return charge;
  };

  setPaymentSubmitted = value => {
    this.paymentSubmitted = value;
  };

  setCharge = charge => {
    this.charge = charge;
  };

  setQuote = quote => {
    this.quote = quote;
  };

  setOrder = order => {
    this.order = order;
  };

  setShowDappCheckout = value => {
    this.showDappCheckout = value;
  };

  get selectedCurrency () {
    if (this.quote) {
      return currencies.find(currency => currency.symbol === this.quote.currency);
    }

    return null;
  }

  get unpaidAmount () {
    if (!this.charge) {
      return null;
    }

    const payment = Object.keys(this.charge?.payments).length && this.charge.payments[this.selectedCurrency?.protocol];

    return payment?.outstanding || this.quote?.amount;
  }

  get qrCodeValue () {
    const { address: sendAddress } = this.quote || {};

    if (this.selectedCurrency?.addressOnlyInQR) {
      return sendAddress;
    }

    if (this.selectedCurrency?.servicePath === 'solana') {
      return this.web3Utils.getSolanaPayQR({
        to: sendAddress,
        amount: this.unpaidAmount,
        memo: this.quote.extraId,
        description: this.charge.description,
        splTokenAddress: this.selectedCurrency?.contractAddress
      });
    }

    let params = { amount: this.unpaidAmount };
    let baseUrl = `${this.selectedCurrency?.protocol}:${sendAddress}`;

    if (this.selectedCurrency?.protocol === 'celo' || this.selectedCurrency?.isCeloERC20) {
      baseUrl = 'celo://wallet/pay';
      params = { address: sendAddress, token: this.selectedCurrency?.displaySymbol, displayName: 'Bidali' };

      if (this.selectedCurrency.fiat) {
        params.currencyCode = this.selectedCurrency?.fiat;
      }
    }

    if (this.selectedCurrency?.servicePath === 'algorand') {
      baseUrl = `algorand://${sendAddress}`;
      params = {};

      if (this.selectedCurrency?.algorandAssetId) {
        return this.quote.address;
      }

      if (this.quote?.extraId) {
        params.xnote = this.quote?.extraId;
      }
    }

    if (this.selectedCurrency?.protocol === 'ethereum') {
      params.value = this.unpaidAmount;
    }

    const qs = Object.keys(params).map(key => `${key}=${params[key]}`).join('&');

    return `${baseUrl}?${qs}`;
  }

  get chainId () {
    const env = config.commerceEnv === 'production' ? 'mainnet' : 'testnet';

    if (!this.selectedCurrency || !this.selectedCurrency.network || !this.selectedCurrency.network.chainIds) {
      return null;
    }

    return this.selectedCurrency.network.chainIds[env]
  }

  get accountNumber () {
    const { accountNumber } = this.items.find(item => item.accountNumber) || {};
    return accountNumber || null;
  }
}
