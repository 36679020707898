import useSWR from 'swr';
import platform from 'platform';
import get from 'lodash.get';
import config from '@/config';
import { shouldIncludeBrandOniOS } from '@/utils';

const fetcher = (...args) => fetch(...args)
  .then(res => res.json())
  .then(result => result.groups || []);

const getBrandsFromResults = (groups = [], category) => {
  const allCategories = groups.map(group => group?.categories).flat();
  const matchingCategory = allCategories.find(c => c.slug === category || c.name === category);

  if (!allCategories.length) {
    return [];
  }

  if (!category) {
    return allCategories[0].brands;
  }

  if (matchingCategory) {
    return matchingCategory.brands;
  }

  return [];
};

export default function useBrands ({ countryId = 'US', search = '', category, apiKey } = {}, fetchFn = fetcher) {
  const params = { countryId };

  if (apiKey) {
    params.apiKey = apiKey;
  }

  if (search) {
    params.$search = search;
  }

  const { data, isLoading, error } = useSWR(
    `${config.commerceApiUrl}/search?${new URLSearchParams(params)}&minimal=true`,
    fetchFn,
    { fallbackData: [], revalidateOnMount: true }
  );

  let brands = getBrandsFromResults(data, category);
  if (brands && brands.length && get(platform, 'os.family') === 'iOS') {
    brands = brands.filter(shouldIncludeBrandOniOS);
  }
  const categories = data
    .map(group => group?.categories)
    .flat()
    .map(category => category?.slug || category?.name);

  return { brands, categories, category: category || categories[0], isLoading, error };
}
